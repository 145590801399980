
import { useField } from 'vee-validate';
import { defineComponent } from 'vue';

export default defineComponent({
     props: {
        name: {
            type: String
            , required: true
        },
        disabled: {
            type: Boolean
            , default: false
        },
        placeholder: {
            type: String
            , default: ''
        }
    },
    setup(props) { 
        const { value, errorMessage } = useField(props.name);

        return { 
            value
            , errorMessage
        }
    }
})
